import { PublicFeedbackDetails } from '../../pages/publicFeedbackDetails';
import { MarketplaceReviewsApi } from './marketplaceReviewsApi';
import { HttpClient, Paged, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const marketplaceReviewsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MarketplaceReviewsApi => {
  const { apiBaseUrl, http } = env;

  const getPageReviewsDecoder = http.decode.response(UnknownApiError, Paged.json(t.array(PublicFeedbackDetails)));

  return {
    getPageReviews: async ({ pageId, limit, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/pages/${encodeURIComponent(pageId)}/reviews`,
            params: {
              page: page,
              limit: limit,
            },
          },
          getPageReviewsDecoder,
        ),
      );
    },
  };
};
