import { ProductId } from '../../pro/products/product/productId';
import { CheckoutReportPercents } from './checkoutReportPercents';
import { CheckoutReportTotals } from './checkoutReportTotals';
import {
  HasId,
  JSONable,
  MeroUnits,
  NonNegativeScaledNumber,
  PortionPercentScaledNumber,
  PositiveInt,
  Numbers,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutProductSalesReportItem<Unit extends MeroUnits.Any> = {
  readonly product: HasId<ProductId> & {
    readonly name: string;
  };
  /**
   * Total number of times product was found in checkout transaction (including products on a booking)
   */
  readonly count: Numbers.Zero | PositiveInt;
  /**
   * Percentage of total count this product item was purchased
   */
  readonly percent: PortionPercentScaledNumber;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
  /**
   * Totals percents
   */
  readonly percents: CheckoutReportPercents;
  /**
   * Total quantity of product in checkout transaction (including products on a booking)
   */
  readonly quantity: NonNegativeScaledNumber;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutProductSalesReportItem<Unit>, JSONable> => {
  return t.type(
    {
      product: t.intersection(
        [
          HasId.json(ProductId),
          t.type(
            {
              name: t.string,
            },
            '!',
          ),
        ],
        'SaleOwner',
      ),
      count: t.union([Numbers.Zero, PositiveInt.JSON]),
      percent: PortionPercentScaledNumber.JSON,
      totals: CheckoutReportTotals.json(unit),
      percents: CheckoutReportPercents.JSON,
      quantity: NonNegativeScaledNumber.JSON,
    },
    `CheckoutProductSalesReportItem<${unit}>`,
  );
};

export const CheckoutProductSalesReportItem = {
  json,
};
