import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';

/**
 * Capitalize a string: first letter to uppercase, others to lowercase
 */
export const capitalize = (s: string): string => {
  return s.length > 0 ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}` : s;
};

const OptionalString = t.union([t.string, t.undefined, t.null], 'OptionalString');

/**
 * Type that decodes to undefined from undefined, null, empty or whitespaces-only string
 */
export const UndefinedString = new t.Type<undefined, undefined, unknown>(
  'UndefinedString',
  t.undefined.is,
  (i, c) => {
    return pipe(
      i,
      OptionalString.decode,
      E.chain((s) => {
        if (s && s.trim()) {
          // string non empty
          return t.failure(
            i,
            c,
            `Failed to decode UndefinedString: undefined, empty or space-only string expected, got ${JSON.stringify(
              i,
            )}`,
          );
        }

        return t.success(undefined);
      }),
    );
  },
  () => undefined,
);

export interface DefinedStringBrand {
  readonly DefinedString: unique symbol;
}

/**
 * Type that has at least one non-whitespace symbol
 */
export type DefinedString = t.Branded<NonEmptyString, DefinedStringBrand>;

export const DefinedString: t.Type<DefinedString, string> = t.brand(
  NonEmptyString,
  (s: NonEmptyString): s is DefinedString => {
    return s.trim() !== '';
  },
  'DefinedString',
);

export interface DefinedTrimedStringBrand {
  readonly DefinedTrimedString: unique symbol;
}

/**
 * Type that has at least one non-whitespace symbols and does not start or end with a whitespace
 */
export type DefinedTrimedString = t.Branded<DefinedString, DefinedTrimedStringBrand>;

const isDefinedTrimedString = (s: unknown): s is DefinedTrimedString => {
  return DefinedString.is(s) && /^[^\s]$/g.test(s[0]) && /^[^\s]$/g.test(s[s.length - 1]);
};

export const DefinedTrimedString: t.Type<DefinedTrimedString, string> = DefinedString.pipe(
  new t.Type<DefinedTrimedString, DefinedString, DefinedString>(
    'DefinedTrimedString',
    isDefinedTrimedString,
    (i, c) => {
      const trimmed = i.trim();

      if (isDefinedTrimedString(trimmed)) {
        return t.success(trimmed);
      }

      return t.failure(i, c, `Failed to decode DefinedTrimedString from ${JSON.stringify(i)}`);
    },
    identity,
  ),
);

export const OptionalDefinedString = t.union([DefinedString, UndefinedString]);
export type OptionalDefinedString = t.TypeOf<typeof OptionalDefinedString>;

function noDiacritics(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const KEYWORDS = [
  'tratament',
  'eximia',
  'remodelare',
  'lifting',
  'peeling',
  'vacuum',
  'ventuze',
  'hidratare',
  'rejuvenare',
  'elos',
  'colagen',
  'mezoterapie',
  'fiola',
  'termoelectroliza',
  'radiofrecventa',
  'microdermabraziune',
  'lipolaser',
  'ultrasunete',
  'electrostimulare',
  'infrarosu',
  'impachetari',
  'vibromasaj',
  'IPL',
  'dioda',
  'masaj',
  'tuns',
  'barbă',
  'barber',
  'barbershop',
  'makeup',
  'make-up',
  'make up',
  'laminare',
  'sprancene',
  'stilizare',
  'anticelulita',
  'anticelulitic',
  'apex',
  'augmentare',
  'balayage',
  'botox',
  'bronz',
  'bronzat',
  'ceara',
  'coafor',
  'coafat',
  'coafuri',
  'mireasa',
  'codeție',
  'cosmetica',
  'curatare',
  'decolorat',
  'vopsit',
  'montare',
  'demontare',
  'dermapen',
  'dermepil',
  'dermocell',
  'epilare',
  'extensii',
  'gene',
  'impletituri',
  'intretinere',
  'injectare',
  'buze',
  'keratina',
  'lashes',
  'machiaj',
  'manichiura',
  'pedichiura',
  'semipermanent',
  'semipermanenta',
  'erotic',
  'microblading',
  'micro pigmentare',
  'micropigmentare',
  'demopigmentare',
  'microneedling',
  'nails',
  'nail',
  'nuantare',
  'oja',
  'pensat',
  'pensare',
  'reflexoterapie',
  'skinfade',
  'skin fade',
  'suvite',
  'tunel',
  'unghii',
  'sector 1',
  'sector 2',
  'sector 3',
  'sector 4',
  'sector 5',
  'sector 6',
].map((keyword) => noDiacritics(keyword).toLowerCase());

const AllowedKeywordsCount = {
  Page: 2,
  Worker: 1,
};

/**
 * Verify if the name contains more keywords than the allowed limit
 */
export function includesMultipleCategoryKeywords(params: { readonly name: string; readonly type: 'Page' | 'Worker' }):
  | {
      readonly includesMultipleCategoryKeywords: false;
    }
  | {
      readonly includesMultipleCategoryKeywords: true;
      readonly includedKeywords: string[];
    } {
  const { name, type } = params;

  const lowerCaseName = noDiacritics(name).toLowerCase();
  const includedKeywords = KEYWORDS.filter((keyword) => new RegExp(`\\b${keyword}\\b`, 'i').test(lowerCaseName));
  return includedKeywords.length > AllowedKeywordsCount[type]
    ? {
        includesMultipleCategoryKeywords: true,
        includedKeywords: includedKeywords,
      }
    : {
        includesMultipleCategoryKeywords: false,
      };
}
